export function languageToCode(lang: string): string {
  if (lang === 'English') {
    return 'en';
  }
  else if (lang === 'Simplified Chinese') {
    return 'zh-cn';
  }
  else if (lang === 'Traditional Chinese') {
    return 'zh-tw';
  }
  else if (lang === 'Indonesian') {
    return 'id';
  }
  else if (lang === 'Vietnamese') {
    return 'vi';
  }
  else if (lang === 'Bahasa Malaysia') {
    return 'ms';
  }
  else if (lang === 'Thai') {
    return 'th';
  }
  else if (lang === 'Korean') {
    return 'ko';
  }
  else if (lang === 'Spanish') {
    return 'es';
  }
  else if (lang === 'Portuguese') {
    return 'pt';
  }
  else if (lang === 'Arabic') {
    return 'ar';
  }
  return lang;
}
