import React, { useState } from 'react';
import './App.css';

import DashInfo from './DashInfo';
import AnalyzeInput from "./features/input/AnalyzeInput";
import useMediaInfo from './hooks/useMediaInfo';



function App() {
  const [dashContent, setDashContent] = useState<any | null>(null);
  const mediaInfo = useMediaInfo(dashContent);

  function handleUpdateDash(js: any) {
    setDashContent(js);
  }

  function handleChangedInput() {
    setDashContent(null);
  }

  return (
    <div>
      <AnalyzeInput onChanged={handleChangedInput} onDashUpdate={handleUpdateDash} />
      { dashContent !== null &&
          <DashInfo mediaInfo={mediaInfo} content={dashContent} />
      }
    </div>
  );
}

export default App;
