import { qualityMap } from './iqiyiConsts';
import prettysize from 'prettysize';

export type SubtitleItem = {
    isSelected: boolean;
    languageName: string;
    languageId: number;
    sortedIndex: number;
    subtitleHost: string;
    srtUri: string;
    xmlUri: string;
    webVttUri: string;
    uuid: string;
    isEncrypted: boolean;
}

export type VideoItem = {
    isSelected: boolean;
    quality: string;
    duration: number;
    mediaHost: string;
    manifestUri: string;
    size: number;
    humanizedSize: string;
    videoId: string;
    m3u8?: string;
}

export default function processDash(js: any) {
    const isValid = js.code === "A00000";
    const subtitlesCount = js.data?.program?.stl?.length;
    const subtitleHost = js.data?.dstl;
    const mediaHost = js.data?.dm;
    let subtitles: SubtitleItem[] = [];
    let needDecrypter: boolean = false;
    if (subtitlesCount > 0) {
        subtitles = js.data.program.stl.map((item: any) => {
            const info: SubtitleItem = {
              isSelected: item._selected,
              subtitleHost: subtitleHost,
              sortedIndex: item._sort,
              srtUri: item.srt,
              webVttUri: item.webvtt,
              xmlUri: item.xml,
              languageName: item._name,
              languageId: item.lid,
              uuid: item.uuid,
              isEncrypted: item.dkid !== undefined,
            };
            if (info.isEncrypted) {
                needDecrypter = true;
            }
            return info;
        });
        subtitles.sort((a, b) => a.sortedIndex - b.sortedIndex);
    }

    const license: string = js.data?.p?.st;

    const videos: VideoItem[] = js.data.program.video.map((item: any) => {
        const info: VideoItem = {
            isSelected: item._selected,
            quality: qualityMap[item.bid].value,
            duration: item.duration,
            size: item.vsize,
            humanizedSize: prettysize(item.vsize),
            videoId: item.vid,
            manifestUri: mediaHost + item.mu,
            mediaHost: mediaHost,
            m3u8: item.m3u8,
        };
        return info;
    }).sort((a: VideoItem, b: VideoItem)=>(b.size - a.size));

    return { isValid, subtitlesCount, subtitles, license, videos, needDecrypter };
}
