import { DecrypterWrapper } from './types';
import { loadMonalisa } from '../../loadMonalisa';
import parser from 'fast-xml-parser';

declare global {
    interface Window { Module: any, wasm_string_strings: any[] }
}
const Module = window.Module = {};

export const wrapper: DecrypterWrapper = {
    isLoaded: false,
    isInitialized: false,
}

export function prepareWrapper(license: string | null, callback: any)
{
    if (!wrapper.isLoaded && !wrapper.isInitialized) {
        window.Module = {
            onRuntimeInitialized: function() {
                console.log('Runtime initialized');
                const e = wrapper;
                const t = window.Module;
                e._canvasId = "subtitlesubtitle";
                e.$canvas = document.createElement("canvas");
                e.$canvas.setAttribute("id", e._canvasId);
                e.$canvas.width = e.$canvas.height = 1;
                e.$context = e.$canvas.getContext("2d");
                e._loading = !1;
                e._moAlloc = t.cwrap("monalisa_context_alloc", "number", []);
                e._moFree = t.cwrap("monalisa_context_free", null, ["number"]);
                e._moSetLicense = t.cwrap("monalisa_set_license", "number", ["number", "string", "number", "string"]);
                e._moSetCanvasId = t.cwrap("monalisa_set_canvas_id", "number", ["number", "string", "number"]);
                e._moSetCanvasFont = t.cwrap("monalisa_set_canvas_font", "number", ["number", "string", "number"]);
                e._moGetLineNumber = t.cwrap("monalisa_get_line_number", "string", ["number", "string", "number", "string"]);
                e._moGetLineWidth = t.cwrap("monalisa_get_line_width", "string", ["number", "string", "number", "string", "string"]);
                e._moDrawText = t.cwrap("monalisa_draw_text", "number", ["number", "string", "number", "string", "number", "number", "boolean"]);
                e._moGetVersion = t.cwrap("monalisa_version_get", "number", []);
                e._ctx = e._moAlloc();
                // const i = 
                e._moSetCanvasId(e._ctx, e._canvasId, e._canvasId.length);
                // const a = 
                e._moGetVersion();
                // console.log('Module version: ', a);
                wrapper.isInitialized = true;
                if (license !== null) {
                    const licResult = e._moSetLicense(e._ctx, license, license.length, "0");
                    if (licResult !== 0) {
                        console.log('License result: ', licResult);
                    }
                }
                if (callback) callback();
            }
        };
        loadMonalisa(()=>{
            wrapper.isLoaded = true;
        });
    }
    if (wrapper.isLoaded && wrapper.isInitialized) {
        if (license !== null && license !== undefined) {
            const licResult = wrapper._moSetLicense(wrapper._ctx, license, license.length, "0");
            console.log('License 2 result: ', licResult);
            // const sub = '4QY9kvrpIM0WvZ23WxaKyV24gR2T3N/kvIPplcj2vVU=';
            // e._moGetLineNumber(e._ctx, sub, sub.length, "<br/>");
            // console.log('Subs:', window.wasm_string_strings);
        }
    }
}

function msToTime(duration: number) {
    let milliseconds = Math.floor((duration % 1000)),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    const hoursS = (hours < 10) ? "0" + hours : hours;
    const minutesS = (minutes < 10) ? "0" + minutes : minutes;
    const secondsS = (seconds < 10) ? "0" + seconds : seconds;
    const msS = (milliseconds < 100) ? ((milliseconds < 10) ? "00" + milliseconds : "0" + milliseconds) : milliseconds;
  
    return hoursS + ":" + minutesS + ":" + secondsS + "," + msS;
  }

export function decryptSubtitles(content: any) {
    // console.log('Content:', content);
    const js = parser.parse(content);
    // console.log('JS subs', js);
    window.wasm_string_strings = [];
    let lines = [];
    for (const item of js.xml.dia) {
        wrapper._moGetLineNumber(wrapper._ctx, item.sub, item.sub.length, "<br/>");
    }
    if (window.wasm_string_strings.length !== js.xml.dia.length) {
        console.error('Subtitles lengths count does not match subtitles count');
        return null;
    }
    for (let i = 0; i < js.xml.dia.length; i++) {
        const startTimeMs = js.xml.dia[i].st;
        const endTimeMs = js.xml.dia[i].et;
        const m = /^var t='((?:(?:\\')|[^'])+)'/m.exec(window.wasm_string_strings[i].req);
        if (m !== null) {
            let t = m[1];
            t = t.replace('\b\t\b', '\'');
            t = t.replace(/([\n])/g, '');
            if (t.charCodeAt(0) === 65279) {
                t = t.slice(1);
            }
            const textArr = t.split('<br/>');
            lines.push(`${i + 1}\r\n`);
            lines.push(`${msToTime(startTimeMs)} --> ${msToTime(endTimeMs)}\r\n`);
            for (const l of textArr) {
                lines.push(l + '\r\n');
            }
            lines.push('\r\n');
        }
    }
    console.log('ALL GOOD');
    return lines;
}