export type QualityMapItem = {
  nbid: number;
  value: string;
  qipu: string;
  obid: number;
};

export type QualityMapType = {
  [t: number]: QualityMapItem;
};

export const qualityMap: QualityMapType = {
  100: {
    nbid: 100,
    value: "240P",
    qipu: "jisu",
    obid: 96,
  },
  200: {
    nbid: 200,
    value: "360P",
    qipu: "300",
    obid: 1,
  },
  300: {
    nbid: 300,
    value: "480P",
    qipu: "600",
    obid: 2,
  },
  500: {
    nbid: 500,
    value: "720P",
    qipu: "720P",
    obid: 4,
  },
  600: {
    nbid: 600,
    value: "1080P",
    qipu: "1080P",
    obid: 5,
  },
  610: {
    nbid: 610,
    value: "1080P50",
    qipu: "1080P50",
    obid: 27,
  },
  700: {
    nbid: 700,
    value: "2K",
    qipu: "2K",
    obid: 6,
  },
  800: {
    nbid: 800,
    value: "4K",
    qipu: "4K",
    obid: 10,
  },
  96: {
    nbid: 100,
    value: "240P",
    qipu: "jisu",
    obid: 96,
  },
  1: {
    nbid: 200,
    value: "360P",
    qipu: "300",
    obid: 1,
  },
  2: {
    nbid: 300,
    value: "480P",
    qipu: "600",
    obid: 2,
  },
  4: {
    nbid: 500,
    value: "720P",
    qipu: "720P",
    obid: 4,
  },
  5: {
    nbid: 600,
    value: "1080P",
    qipu: "1080P",
    obid: 5,
  },
  27: {
    nbid: 610,
    value: "1080P50",
    qipu: "1080P50",
    obid: 27,
  },
  6: {
    nbid: 700,
    value: "2K",
    qipu: "2K",
    obid: 6,
  },
  10: {
    nbid: 800,
    value: "4K",
    qipu: "4K",
    obid: 10,
  },
};
