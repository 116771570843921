import React, { useState, useMemo, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import validateDashUrlContent from './validateUrl';
import useDashFetch from './useDashFetch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: "10pt",
        display: "flex",
        flexWrap: "wrap",
    },
    progress: {
        marginTop: 10,
        marginBottom: 10,
        width: '100%'
    }
  })
);

type Props = {
    onChanged(): void,
    onDashUpdate(js: any): void,
};


export default function AnalyzeInput({ onChanged, onDashUpdate }: Props) {
    const classes = useStyles();
    const [value, setValue] = useState<string>("");
    const validation = useMemo(() => validateDashUrlContent(value), [value]);

    const [submittedURL, setSubmittedURL] = useState<string | null>(null);
    const { isLoading, progress } = useDashFetch(submittedURL, onDashUpdate);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const s = event.target.value;
        console.log('Value changed');
        setValue(s);
        onChanged();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validation.isJson) {
            onDashUpdate(validation.json);
        }
        if (validation.isURL && validation.url !== undefined) {
            setSubmittedURL(validation.url.toString());
        } else {
            setSubmittedURL(null);
        }
        console.log('Submitted');
    }

    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="content"
          error={!validation.isValid}
          style={{ margin: 8 }}
          label="DASH URL or JSON response"
          placeholder="Input full URL of DASH or paste its response"
          value={value}
          onChange={handleChange}
          multiline
          rowsMax={6}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!validation.isValid || !validation.isURL}
        >
          Download &amp; Analyze Dash
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!validation.isValid || !validation.isJson}
        >
          Analyze Dash
        </Button>
        {isLoading && (
          <div className={classes.progress}>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
      </form>
    );
}