export type DashUrlValidationResult = {
    isValid: boolean;
    isURL: boolean;
    isJson: boolean;
    url?: URL,
    json?: any,
}

export default function validateURL(s: string): DashUrlValidationResult {
    try {
        const url = new URL(s);
        if (url.host === "cache-video.iq.com" && url.pathname === '/dash') {
            return {isValid: true, isURL: true, isJson: false, url: url};
        } else {
            return { isValid: false, isURL: true, isJson: false };
        }
    } catch (TypeError) {
        try {
            let js = JSON.parse(s);
            if (js.tvid && js.program) {
                js = {
                    code: 'A00000',
                    data: {
                        ...js,
                    }
                };
            }
            if (js.data && js.code && js.data.program) {
                return {isValid: true, isURL: false, isJson: true, json: js};
            } else {
                return { isValid: false, isURL: false, isJson: true };
            }
        }
        catch (SyntaxError) {
            return {isValid: false, isURL: false, isJson: false};
        }
    }
}
