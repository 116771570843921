import React, { useEffect, useMemo } from 'react';

import styles from "./DashInfo.module.css";
import processDash from "./processDash";
import SubtitlesListTable from './SubtitlesListTable';
import VideoListTable from './VideoListTable';
import Typography from '@material-ui/core/Typography';
import { prepareWrapper } from './features/wasm/decryptingSubtitles';
import { MediaInfoShort } from './types';

type Props = {
    content: any,
    mediaInfo: MediaInfoShort | null,
}

export default function DashInfo({content, mediaInfo} : Props) {
    const { isValid, subtitlesCount, subtitles, license, videos, needDecrypter } = useMemo(()=>processDash(content), [content]);
    useEffect(() => {
      if (!needDecrypter) {
        console.log('Decrypter do not needed');
        return;
      }
      console.log('Decrypter do needed');
      prepareWrapper(license, ()=>{
        console.log('Wrapper ready');
        // console.log('Setting license;')
        // const licResult = e._moSetLicense(e._ctx, license, license.length, "0");
        // console.log('License result: ', licResult);
      });
    }, [needDecrypter, license])
    useEffect(()=>{
      // console.log('License2:', license);
    }, [license])
    // console.log('License:', license);
    return (
      <div className={styles.mainContainer}>
        <Typography variant="h4">DASH information</Typography>
        <p>
          <span>Is Valid: </span>
          {isValid ? "Yes" : "No "}
        </p>
        { mediaInfo &&
          (<><p><span>Title: </span>
            {mediaInfo.title}
          </p>
          { mediaInfo.isSeries && (
            <p><span>Episode index: </span>{mediaInfo.episodeIndex}</p>)
          }
          <p><span>Description: </span>{mediaInfo.description}</p>
          </>
        )}
        <p>
          <span>Subtitles count: </span>
          {subtitlesCount}
        </p>
        {license && (
          <p style={{ wordWrap: "break-word" }}>
            <span>Subtitles decryption key: </span>
            {license}
          </p>
        )}
        <br />
        <VideoListTable mediaInfo={mediaInfo} videos={videos} />
        <SubtitlesListTable mediaInfo={mediaInfo} subtitles={subtitles} />
      </div>
    );
}

