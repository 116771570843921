import { useState, useEffect} from 'react';

export default function useDashFetch(url: string | null, updateCallback: (dashContent: string)=> void) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    useEffect(()=>{
        // console.log("Effect. useDashFetch", url);
        if (url !== null) {
            const req = new XMLHttpRequest();
            req.open("GET", url);
            req.onreadystatechange = function () {
            if (req.readyState !== 4) return;

            if (req.status !== 200) {
                console.log("Req error:", req.status);
            } else {
                let js = JSON.parse(req.responseText);
                if (js.tvid && js.program) {
                    js = {
                        code: 'A00000',
                        data: {
                            ...js,
                        }
                    };
                }
                updateCallback(js);
            }
            setIsLoading(false);
            };
            req.onprogress = function (event) {
            setProgress((100 * event.loaded) / event.total);
            };
            req.send();
            setIsLoading(true);
        }
    }, [url]);
    
    return {isLoading, progress};
}
