import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { VideoItem } from './processDash';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { saveAs } from "file-saver";
// const useStyles = makeStyles({
//   table: {
//     minWidth: 150,
//   },
// });

type Props = {
  mediaInfo: any,
  videos: VideoItem[];
};

function numberToEpisode(n: number): string
{
  if (n < 10) {
    return "0" + n;
  } else {
    return "" + n;
  }
}

export default function VideoListTable({
  videos, mediaInfo
}: Props) {
  function exportM3U8(m3u8: any,) {
    console.log('m3u8: ', m3u8);
    console.log('meida info: ', mediaInfo);
    const blob = new Blob([m3u8], {type: 'text/plain'});
    if (mediaInfo === null) {
      saveAs(blob, `iqiyi-video.m3u8`);
    } else {
      const filename: string = mediaInfo.isSeries ? `${mediaInfo.fnTitle}.E${numberToEpisode(mediaInfo.orderIndex)}.m3u8` : `${mediaInfo.fnTitle}.m3u8`;
      saveAs(blob, filename);
    }
  }

  return (
    <>
      <Typography variant="h6">Videos (just for reference):</Typography>
      <TableContainer component={Paper}>
        <Table
          size="small"
          aria-label="subtitles list table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Quality</TableCell>
              <TableCell>Selected</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Manifest URI</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>M3U8</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((row: VideoItem) => (
              <TableRow key={row.videoId}>
                <TableCell component="th" scope="row">
                  {row.quality}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.isSelected}
                    name="isSelected"
                    color="primary"
                  />
                </TableCell>
                <TableCell>{row.humanizedSize}</TableCell>
                <TableCell>{moment.duration(row.duration * 1000).humanize({m: 55})}</TableCell>
                <TableCell>
                  <a href={row.manifestUri}>XML</a>
                </TableCell>
                <TableCell>{row.videoId}</TableCell>
                <TableCell>
                  {!!row.m3u8 &&
                  (<Button
                    onClick={()=>exportM3U8(row.m3u8)}
                    variant="contained"
                    color="secondary"
                  >Export</Button>)
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
