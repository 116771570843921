import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { SubtitleItem } from './processDash';
import Checkbox from "@material-ui/core/Checkbox";
import { saveAs } from "file-saver";
import {decryptSubtitles} from './features/wasm/decryptingSubtitles';
import { MediaInfoShort } from "./types";
import { languageToCode } from './utils/languageToCode';

// const useStyles = makeStyles({
//   table: {
//     minWidth: 150,
//   },
// });

type Props = {
  mediaInfo: MediaInfoShort | null,
  subtitles: SubtitleItem[];
};

export default function SubtitlesListTable({
  subtitles, mediaInfo
}: Props) {
  function handleUrlClick(event: any, item: SubtitleItem, format: string) {
    event.preventDefault();
    const postData = {
      host: item.subtitleHost,
      srt: item.srtUri,
      xml: item.xmlUri,
      webvtt: item.webVttUri,
      selected: item.isEncrypted ? 'xml' : format,
    };
    
    (async () => {
      const rawResponse = await fetch("/api/download", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      const answer = await rawResponse.json();
      const content = answer.content;
      let blob;
      if (!item.isEncrypted) {
        blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      } else {
        const lines = decryptSubtitles(content);
        if (lines === null) {
          return;
        }
        blob = new Blob(lines, { type: "text/plain;charset=utf-8" });
      }
      const filename: string =
        mediaInfo !== null
          ? `${mediaInfo.filenamePrefix}.${languageToCode(
              item.languageName
            )}.${format}`
          : `subtitles.${item.languageName}.${languageToCode(
              item.languageName
            )}.${format}`;
      console.log("Saving");
      saveAs(blob, filename, { autoBom: true });
    })();
  }

  return (
    <>
      <Typography variant="h6">Subtitles:</Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="subtitles list table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Selected</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>UUID</TableCell>
              <TableCell>Encrypted</TableCell>
              <TableCell>Srt</TableCell>
              <TableCell>Xml</TableCell>
              <TableCell>WebVTT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtitles.map((row: SubtitleItem) => (
              <TableRow key={row.uuid}>
                <TableCell component="th" scope="row">
                  {row.sortedIndex}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.isSelected}
                    name="isSelected"
                    color="primary"
                  />
                </TableCell>
                <TableCell>{row.languageName}</TableCell>
                <TableCell>{row.uuid}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={row.isEncrypted}
                    name="isEncrypted"
                    color="secondary"
                  />
                </TableCell>
                <TableCell>
                  <a href={row.subtitleHost + row.srtUri} onClick={(e)=>handleUrlClick(e, row, 'srt')}>Srt</a>
                </TableCell>
                <TableCell>
                  {!row.isEncrypted && (<a href={row.subtitleHost + row.xmlUri} onClick={(e)=>handleUrlClick(e, row, 'xml')}>XML</a>)}
                </TableCell>
                <TableCell>
                  {!row.isEncrypted && (<a href={row.subtitleHost + row.webVttUri} onClick={(e)=>handleUrlClick(e, row, 'webvtt')}>WebVTT</a>)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
