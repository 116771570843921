import { useState, useEffect } from 'react';
import { MediaInfoShort } from '../types';
import { numberToEpisode } from '../utils/numberToEpisode';


export default function useMediaInfo(dashContent: any | null) {
    const [ mediaInfo, setMediaInfo ] = useState<MediaInfoShort | null>(null);

    useEffect(()=>{
        // console.log('Effect useMediaInfo');
        if (dashContent === null) {
            setMediaInfo(null);
            return;
        }
        // const albumId = dashContent.data?.aid;
        const mediaId = dashContent.data?.tvid;
        const url = `https://pcw-api.iq.com/api/epgInfo/${mediaId}?platformId=3&langCode=en_us&modeCode=intl&deviceId=ew&uid=&pspStatus=`;
        const req = new XMLHttpRequest();

        req.open('GET', url);
        req.onreadystatechange = function() {
            if (req.readyState !== 4) return;

            if (req.status !== 200) {
                setMediaInfo(null);
                return;
            }

            const js = JSON.parse(req.responseText);
            console.log('Media info: ', js);
            const title = js.data?.albumName;
            const isSeries = js.data?.isSeries === 1;
            const episodeIndex = js.data?.order;
            const description = js.data?.desc;
            const sluggedTitle = title.replace(/([^\w\d]+)/g, ".");
            const filenamePrefix = isSeries
              ? `${sluggedTitle}.E${numberToEpisode(episodeIndex)}`
              : sluggedTitle;
            const info: MediaInfoShort = {
                title,
                isSeries,
                episodeIndex,
                sluggedTitle,
                filenamePrefix,
                description,
            };
            setMediaInfo(info);
        }
        req.send();
    }, [dashContent]);

    return mediaInfo;
}
