export const loadMonalisa = (callback: any) => {
    const existingScript = document.getElementById('monalisa');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '/libmonalisa-v3.0.4-browser.js';
      script.id = 'monalisaReact';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
      
    }

    if (existingScript && callback) callback();
  }
